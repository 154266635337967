@import '../../styles/mixins/breakpoints';
@import '../../styles/variables';

.flex-table {
  background-color: white;
  border: 1px solid $color-grey;
  font-weight: $font-weight-primary;
  margin-left: auto;
  margin-right: auto;
}

.table-row {
    border-bottom: 1px solid $color-grey;
    border-collapse: collapse;
    display: flex;
    flex-direction: column;
    padding: $size-gutter * 0.5;
    width: 100%;

    @include breakpoint(laptop) {
      flex-direction: row;
      padding: $size-gutter * 0.5 0;
    }

    &.header {
      background: $color-bg;
      padding-bottom: $size-gutter * 0.5;
      padding-top: $size-gutter * 0.5;
      .table-data {
        font-size: 0.75em;
        font-weight: 300;
      }
    }

    &.cursor {
      cursor: pointer;
    }
}

.table-data {
  align-items: center;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 14px;
  overflow: hidden;
  padding-left: $size-gutter * 0.5;
  padding-right: $size-gutter * 0.5;

  flex: 1;

  input {
    &.disabled {
      background-color: $color-grey;
    }
  }

  &.index {
    max-width: 26px;
    text-align: center;
    width: 26px;
  }

  &.actions {
    justify-content: center;
    max-width: 96px;
    // width: 64px;
  }
}
