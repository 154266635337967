@mixin reset-box-model {
  border: 0;
  margin: 0;
  padding: 0;
}

@mixin reset-list {
  @include reset-box-model;
  list-style: none;

  li {  @include reset-box-model; }
}

@mixin reset-form {
  fieldset {
    @include reset-box-model;
  }

  legend { display: none; }
}
