@import '../../styles/variables';

.comment-section {

  .comment {
    border-bottom: 1px solid $color-grey;
    font-size: 14px;
    padding: 8px 16px;


    &:hover {
      border-left: 4px solid $color-brand-primary;

      .wrapper {margin-left: -4px;}
    }

    .author-details {
      font-size: 14px;
      .author {
        color: #3b73af;
      }
    }
  }
}
