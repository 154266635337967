@import '../../styles/variables';

@mixin paint-message($color) {
  background: $color;

  &::before,
  &::after {
    background: $color;
  }
}

@keyframes reveal {
  0% { transform: translate(0, 100%); }
  100% { transform: translate(0, 0); }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.signal {
  animation-duration: .75s;
  animation-name: reveal;
  animation-timing: ease-out;
  background: transparent;
  bottom: 2.5rem;
  left: 1rem;
  max-width: 320px;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 1000;

  .message {
    @include paint-message($color-brand-primary);
    animation-duration: 1s;
    animation-name: fadeIn;
    margin-bottom: 3.25rem;
    padding: .5rem 2rem;
    position: relative;

    &::before,
    &::after {
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
    }

    &.error { @include paint-message($color-error); }
    &.warn { @include paint-message($color-warn); }
    &.info { @include paint-message($color-info); }
    &.suggest { @include paint-message($color-suggest); }
    &.success { @include paint-message($color-success); }
  }
}
