@import '../../../styles/variables';
@import '../mixins';

@include input-text-types { @include form-input; }

textarea {
  @include form-input;
  background-color: white;
  // position: relative;
  height: 150px;
}

select {
  @include form-input;
  background-color: white;
  // position: relative;
  padding-top: 0;
  padding-bottom: 0;
  // &::after {
  //   content: '▼';
  //   display: block;
  //   font-size: 2em;
  //   margin-top: -50%;
  //   position: absolute;
  //   right: 1rem;
  //   top: 1rem;
  // }

  &:active,
  &:focus {
    // color: $color-tertiary;
  }

  option {
    // color: $color-black;
    // font-weight: $font-bold;

    &.hide-option {
      display: none;
      font-size: 0;
      height: 0;
      visibility: hidden;
    }
  }
}

// TODO finish the styling on this range slider
// input {
//   &[type='range'] {
//     -webkit-appearance: none;
//     margin: 13.8px 0;
//     width: 100%;
//
//     &::-webkit-slider-runnable-track {
//       background: #3071a9;
//       border: .2px solid #010101;
//       border-radius: 1.3px;
//       box-shadow: none;
//       cursor: pointer;
//       height: 8.4px;
//       width: 100%;
//     }
//
//     &::-webkit-slider-thumb {
//       -webkit-appearance: none;
//       background: $color-db-text;
//       border: 0;
//       border-radius: 100%;
//       box-shadow: none;
//       cursor: pointer;
//       height: 2em;
//       margin-top: -2em;
//       width: 2em;
//     }
//
//     &::-moz-range-track {
//       background: #3071a9;
//       border: .2px solid #010101;
//       border-radius: 1.3px;
//       box-shadow: none;
//       cursor: pointer;
//       height: 8.4px;
//       width: 100%;
//     }
//
//     &::-moz-range-thumb {
//       background: #ffffff;
//       border: 1px solid #000000;
//       border-radius: 3px;
//       box-shadow: none;
//       cursor: pointer;
//       height: 36px;
//       width: 16px;
//     }
//
//     &::-ms-track {
//       background: transparent;
//       border-color: transparent;
//       color: transparent;
//       cursor: pointer;
//       height: 8.4px;
//       width: 100%;
//     }
//
//     &::-ms-fill-lower {
//       background: #2f6ea5;
//       border: .2px solid #010101;
//       border-radius: 2.6px;
//       box-shadow: none;
//     }
//
//     &::-ms-fill-upper {
//       background: #3071a9;
//       border: .2px solid #010101;
//       border-radius: 2.6px;
//       box-shadow: none;
//     }
//
//     &::-ms-thumb {
//       background: #ffffff;
//       border: 1px solid #000000;
//       border-radius: 3px;
//       box-shadow: none;
//       cursor: pointer;
//       height: 36px;
//       width: 16px;
//     }
//
//     &:focus {
//       outline: none;
//
//       &::-webkit-slider-runnable-track {
//         background: #3174ad;
//       }
//
//       &::-ms-fill-lower {
//         background: #3071a9;
//       }
//       &::-ms-fill-upper {
//         background: #3174ad;
//       }
//     }
//   }
// }

.defaultid_form{
  width: 18%;
  padding-right: 10px;
}

.optumId_form{
  padding-right: 10px;;
}
