@import '../../styles/mixins/breakpoints';

.form-control {
  &.filter {
    input {
    // font-size: 10px;
      font-size: 10px;
      font-style: italic;
      padding: 0.75em;
      height: auto;

      @include breakpoint (laptop) {
        max-width: 150px;
      }
    }

    &.search-filter {
      @include breakpoint (laptop) {
        max-width: auto;
      }
    }

    &.active {
      input {
        border-color: lightgreen;
      }
    }
  }
}
