.autocomplete {
  // position: relative;
  .autocomplete-results {
    position: absolute;
    margin-top: 4px;
    min-width: 400px;
    max-height: 400px;
    overflow: hidden;
    overflow-y: scroll;
    z-index: 100;

    .autocomplete-row {
      padding: 0.1em 1em;
      &:hover {
        background-color: darken(white, 5%);
      }
    }
  }
}
