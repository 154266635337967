@import '../variables';
@import '../mixins/reset';

@mixin button-reset {
  @include reset-box-model;
  outline: none;
  text-align: center;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
}

@mixin button {
  @include button-reset;
  border-radius: $border-radius;
  display: inline-block;
  font-family: $font-primary;
  font-weight: 200;
  font-size: 0.75em;
  padding: .5rem;
  width: 100%;

  &:focus { outline: none; }
}

@mixin button-sm {
  @include button;
  font-weight: normal;
  font-size: .7em;
  line-height: 1;
  padding: .25rem .5rem;
  width: auto;
}

@mixin button-icon {
  background: transparent;
  border: 0;
  color: $color-brand-primary;

  i {
    font-size: 16px;
  }

  &:hover {
    background: $color-brand-primary;
    color: $color-white;
  }
}

@mixin button-style-outline {
  background: transparent;
  border: 2px solid $color-text;
  color: $color-text;

  &:hover {
    background: $color-text;
    color: $color-bg;
  }
}


@mixin actions-default {
  > {
    .button {
      &:first-child {
        border-radius: $border-radius 0 0 $border-radius;
        border-right: transparent;
      }

      &:last-child {
        border-radius: 0 $border-radius $border-radius 0;
      }
    }
  }
}
