@import '../../styles/variables';

.card {
  background-color: white;
  border: 1px solid transparent;
  border-color: $color-grey;
  box-shadow: 0 1px 1px rgba(0,0,0,0.05);
  margin-bottom: $size-gutter;

  &.padding {
    padding: $size-gutter;
  }
}

.card.closed-case{
  box-shadow: 1px 1px 2px 1px grey;
  .row{
    margin-top: 1em;
  }
}
