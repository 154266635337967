@import '../../styles/variables';

.loading-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  &.inline {
    height: auto;
    width: auto;
    display: inline-block;
  }

  .loading-spinner {
      border: 5px solid #f3f3f3; /* Light grey */
      border-top: 5px solid $color-brand-primary; /* Blue */
      border-radius: 50%;
      width: 64px;
      height: 64px;
      animation: spin 1.25s linear infinite;

      &.white {
        border-top-color: #fff;
      }

      &.small {
        margin-left: calc($size-gutter /4);
        margin-right: calc($size-gutter /4);
        width: 16px;
        height: 16px;
        border-width: 2px;
      }
  }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
