@import 'variables';
@import 'mixins/reset';

body {
  font-family: $font-primary;
  font-size: $font-size-primary;
  font-weight: normal;
  line-height: $line-height-primary;
}

h1,
h2,
h3,
h4,
h5 {
  @include reset-box-model;
  font-family: $font-primary;
  margin: 1em; // TODO $size-gutter ?;
}

h1,
h2 {
  font-size: $font-size-secondary;
  font-weight: $font-weight-heading;
  text-align: center;

  @include breakpoint(tablet) {
    text-align: left;
  }
}

h3 {
  font-size: $font-size-tertiary;
}

blockquote {
  font-size: 105%;
  margin: 3 * $size-gutter 1rem;
  padding: 0 3 * $size-gutter;
  border-left: 4px solid #c3c3c3;
}

p {
  &:first-child { margin-top: 0; }
}

.page-heading {
  font-size: $font-size-tertiary;
  margin: $size-gutter 0;
}
