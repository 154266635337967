@import '../../../styles/variables';

$side-nav-item-height: 36px;
$icon-size: 24px;

ul.side-nav {
  background-color: $color-bg;
  border-right: 2px solid $color-grey;
  list-style: none;
  margin: 0;
  padding: 0;
  position: fixed;
  width: calc(#{$side-nav-width} + 10px);
  height: 100%;
  max-height: 90%;
  overflow-y: scroll;
  overflow-x: hidden;

  li  {
    background-color: $color-bg;
    color: $color-primary;

    a {
      align-items: center;
      color: $color-grey-dark;
      cursor: pointer;
      display: flex;
      height: $side-nav-item-height;
      padding-left: 4px;

      i {
        font-size: 20px;
        width: $icon-size;
      }

      span {
        line-height: 14px;
        font-size: 14px;
      }

      &:hover {
        background-color: darken($color-bg, 5%);
      }
    }

    &.active {
      background-color: darken($color-bg, 5%);
      border-left: 4px solid $color-brand-primary;

      li {
        background-color: darken($color-bg, 5%);

        &:hover {
          background-color: darken($color-bg, 10%);
        }
      }
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    .sub-level-item {
      padding-left: $icon-size;
      a {
        align-items: center;
        display: inline-block;
        &:hover {
          background-color: transparent;
        }
      }

      &:hover {
        background-color: darken($color-bg, 10%);
      }

      &.active {
        border-left: 0;
        background-color: darken($color-bg, 10%);
        a {
          color: $color-brand-primary;
        }
      }
    }
  }
}

//TODO: put this somehwere else.
.side-nav-content {
  margin-left: $side-nav-width;
  width: calc(100% - #{$side-nav-width});
}
