@import '../styles/mixins/touchables';
@import '../styles/variables';

.button {
  @include button;
}

.primary-button {
  background-color: $color-brand-primary;
  color: white;
}

.inverted-button {
  background-color: white;
  border: 1px solid $color-brand-primary;
  color: $color-brand-primary;
}

.secondary-button {
  background-color: white;
  border: 1px solid $color-grey;
  color: $color-brand-primary;
}

.close-submit.disabled{
  background-color: $color-grey;
}

.side-by-side {
  display:flex; 
  flex-direction:row;
}

.disabled-button {
  background-color: grey;
  color: white;
  cursor: not-allowed;
  border: 0;
  margin: 0;
  padding: 0;
  outline: none;
  text-align: center;
  text-decoration: none;
  border-radius: 3px;
  display: inline-block;
  font-family: "Open Sans", "Helvetica Neue", "Helvetica", sans-serif;
  font-weight: 200;
  font-size: 0.75em;
  padding: .5rem;
  width: 100%;
  pointer-events: none;
}

.mini-button {
  background-color: $color-brand-primary;
  color: white;
  border: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  outline: none;
  text-align: center;
  text-decoration: none;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Open Sans", "Helvetica Neue", "Helvetica", sans-serif;
  font-weight: 200;
  font-size: 0.75em;
  padding: .5rem;
  width: 30%;
}