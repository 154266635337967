@import 'variables';
@import 'mixins/breakpoints';
@import 'mixins/reset';
@import 'typography';

//TODO: Refactor these
.page {
  margin-left: auto;
  margin-right: auto;
  padding: $size-gutter;
  width: 100%;

  @include breakpoint(tablet) {
    width: 80%;
  }
}

.search-page {
  margin-left: auto;
  margin-right: auto;
  padding: $size-gutter;
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  margin-left: -1em;
  margin-right: -1em;
}

.col {
  display: flex;
  flex: 1;
  padding-left: 1em;
  padding-right: 1em;
}

.overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 25em;
}

html { box-sizing: border-box; }

body {
  @include reset-box-model;
  background: $color-bg;
  color: $color-text;
  padding-top: 5rem; // Padding for sticky nav
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

h1, h2, h3, h4, h5, p {
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

a {
  color: #7ec0ee;
  cursor: pointer;
}

.orange {
  color: $color-brand-primary;
}

.red {
  color: red;
}

.action {
  font-size: 1.4em;
}

.text-right {
  text-align: right;
}

.margin-left {
  margin-left: 1em;
}

.margin-right {
  margin-right: 1em;
}

.margin-top {
  margin-top: 1em;
}

.margin-bottom {
  margin-bottom: 1em;
}

.fixed-height-scroll {
  max-height:550px;
  overflow-y:scroll;
}

.line {
  height: 1px;
  background-color: $color-grey;
}

.align-items-start {
  align-items: flex-start;
}

.overflow-wrap {
  overflow-wrap: anywhere;
}

.grid-width-50 {
  width: 50%;
}

.grid-width-25 {
  width: 25%;
}

.width-auto {
  width: auto;
}