@import '../../styles/variables';

.overlay-style {
  background-color: rgba(0,0,0,0.7);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
}

.modal {
  background-color: #fff;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  min-width: 50%;
  overflow: auto;
  padding: $size-gutter ;
  position: fixed;
  z-index: 10001;
  display: flex;
  flex-direction: column;

  max-height: 80%;
  max-width: 500px;
  width: 60vw;
  left: 50%;
  top: 50%;
  right:auto;
  bottom: auto;
  margin-bottom: 1rem;
  transform: translate(-50%, -50%);


  .modal-header {
    align-items: center;
    background-color: white;
    border-bottom: 1px solid $color-grey;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    h4 {
      width: 100%;
    }

    .close-button {
      background-color: white;
      outline: 0;
      border: 0;
      cursor: pointer;
      font-size: 20px;
    }
  }


  .modal-body {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;
    margin: 0;
  }

  .modal-footer {
    border-top: 1px solid $color-grey;
    padding-top: $size-gutter;

    .modal-actions {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .button {
        margin-left: 1em;
        margin-right: 1em;
      }

      .right {
        float: right;
      }
    }
  }
}
