@import '../../../styles/variables';

.form-section {
  border: 1px solid $color-grey;
  padding: 0;

  .header {
    background-color: $color-bg;
    border-bottom: 1px solid $color-grey;
    padding: calc($size-gutter/2);
  }

  .body {
    background-color: white;
    padding: $size-gutter;
  }
}
