@import '../../styles/variables';


// Helper Methods -----------------------------------
@mixin input-text-types {
  input {
      &[type='date'],
      &[type='email'],
      &[type='month'],
      &[type='number'],
      &[type='password'],
      &[type='search'],
      &[type='tel'],
      &[type='text'],
      &[type='url'] {
        @content;
      }
    }
  }


  // Default Form Element Styling -----------------------------------------------
  @mixin form-input {
    // background: $color-bg-translucent;
    // border: $form-border;
    // color: $color-form-text;
    // font-family: $font-primary;
    // font-size: $font-form-size;
    // font-weight: $font-normal;
    // margin-bottom: .75rem
    border: 1px solid $color-grey;
    border-radius: $border-radius;
    color: $color-primary;
    height: 32px;
    padding: .75em;
    transition: .2s ease-out background-color;
    width: 100%;

    &::placeholder {
      // color: $color-form-placeholder;
      // font-weight: $font-light;
    }

    &:focus {
      //background: $color-white;
      //font-weight: $font-bold;
      outline: none;
      transition: none;
    }

    &[type='date'],
    &[type='month'] {
    &::before {
      // color: $color-form-placeholder;
      content: attr(placeholder);
      margin-right: .5em;
    }

    &:focus,
    &:valid {
      &::before {
        content: '';
      }
    }
  }
}


// Form Layout Mixins ---------------
@mixin field-neighbors {
  display: flex;

  .form-control {
    flex: 1;
    margin: 0 .325rem;

    &:first-child { margin-left: 0; }

    &:last-child { margin-right: 0; }
  }
}

//TODO: Refactor this
.input-label {
  font-size: 12px;
}

.form-control {
  width: 100%;
}

fieldset {
  border: 0;
  padding: 0;
}

.flex-display{
  display: flex;
}
