// Variables -------------------------------------------------------------------
$color-primary: #464646;
$color-white: #fff;
$color-brand-primary: #f69130;
$color-brand-secondary: #84be46;

// TODO default colors...
$color-error: #f57e7e;
$color-warn: #f5aa7e;
$color-suggest: #b17ef5;
$color-info: #1d5a96;
$color-success: #09d8b2;

// Colors, brand color, etc -----
$color-bg: #F8FAFD;
$color-grey: #ebedf1;
$color-grey-dark: darken(#ebedf1, 60);
$color-text: $color-primary;

// Sizes and style -----
$border-radius: 3px;
$font-primary: 'Open Sans', 'Helvetica Neue', 'Helvetica', sans-serif;
$font-size-primary: 1em;
$font-size-secondary: 2.5em;
$font-size-tertiary: 1.75em;
$font-weight-primary: 400;
$font-weight-heading: 300;
$line-height-primary: 1.75;

// Breakpoints and reactive elements ----


// Layout sizes and definitions -----
$size-gutter: 1rem;

$side-nav-width: 200px;


// Mainfest -------------------
@import './mixins/breakpoints';
