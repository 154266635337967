@import '../../styles/variables';
.snackbar {
  visibility: hidden;
  min-width: 300px;
  max-width: 300px;
  margin-left: -150px;
  text-align: center;
  border-radius: 2px;
  padding: 24px;
  position: fixed;
  z-index: 2;
  left: calc(80% + 100px);
  top: 100px;
  font-size: 16px;
  display: flex;
  align-items: center;

  .icon {
    flex: 1;

    &.error {
      color: red;
      border-color: red;
    }

    &.info {
      color: lightgreen;
      border-color: lightgreen;
    }

    &.warn {
      color: orange;
      border-color: orange;
    }
  }

  .content {
    position: relative;
    flex: 3;
    font-size: 14px;

    .close-button {
      position: absolute;
      top: -20px;
      right: -12px;
      background: transparent;
      border: 0;
      padding: 0;
      outline: none;
      cursor: pointer;
    }

    span {
      display: block;
    }
  }

  &.show {
    visibility: visible;
    // -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    // animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }
}

@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}