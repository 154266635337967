@import '../../styles/variables';
.tabs {
  display: flex;
  flex-direction: row;

  .tab {
    align-items: center;
    background-color: white;
    border: 1px solid $color-grey;
    border-radius: 0;
    color: $color-brand-secondary;
    cursor: pointer;
    justify-content: center;
    min-height: 32px;
    min-width: 32px;
    outline: none;
    font-size: 12px;
    padding: 0;

    &:hover {
      background-color: darken(white, 7%);
    }

    &:first-child {
      border-bottom-left-radius: 0%;
      border-top-left-radius: 0%;
      border-right-width: 0px;
    }

    &:last-child {
      border-bottom-right-radius: 0%;
      border-top-right-radius: 0%;
      // border-left-width: 1px;
      border-right-width: 1px;
    }

    &.active {
      background-color: $color-brand-secondary;
      color: white;
    }
  }
}
