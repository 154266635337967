@import '../../styles/variables';

.text-field {
  font-size: 14px;
  width: 100%;
  .text-container {
    border-bottom: 1px solid $color-grey;
    min-height: 25px;
  }
}

.closed-case .input-label {
  font-weight: bold;
}

.closed-case span {
  font-size: 12px;
}

.prevCloseNotes {
  border-bottom: 1px solid #ebedf1;
    overflow-y: scroll;
    height: 100px;
}