.Hierarchy-search {
  .row {
    align-items: flex-end;
    display: flex;
    flex-direction: row;

    .col {
      display: flex;;
      flex: 1;
    }
    .col-5 {
      display: flex;;
      flex: 5;
    }

    .button {
      height: 36px;
      padding-top: initial;
      padding-bottom: initial;
    }
  }
}
