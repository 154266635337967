@import '../../styles/variables';

.site-nav {
  background: $color-white;
  box-shadow: 0 0 6px rgba($color-text, .4);
  height: 5rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 101;

  h1 {
    font-size: 1em;
  }

  .logo {
    height: 3.5rem;
    width: auto;
  }
}
